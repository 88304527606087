import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { updateTenant, deleteTenant } from "services/tenants";
import countryList from "helpers/countryList";
import { routeNames } from "routes";
import NewCompanyCongratulations from "components/NewCompanyCongratulations";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add, DeleteOutline } from "@mui/icons-material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import { useLoadingContext } from "components/contexts/LoadingContext";

const CompanyInfo = () => {
  const { selectedTenant, tenants, setUpdateTrigger, updateTrigger } =
    useUserContext();

  const { loading, setLoading } = useLoadingContext();
  const [show, setShow] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state }: any = useLocation();
  const [deleteCompanyName, setDeleteCompanyName] = React.useState("");
  const translation = useTranslation();
  const navigate = useNavigate();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();

  const companySchema = Yup.object().shape({
    companyName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    companyLegalName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),

    addressLine1: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    addressLine2: Yup.string().max(255, translation.signUpLongError),
    city: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    state: Yup.string()
      .min(2, translation.signUpShortError)
      .max(127, translation.signUpLongError)
      .required(translation.required),
    zipCode: Yup.string()
      .min(4, translation.signUpShortError)
      .max(31, translation.signUpLongError)
      .required(translation.required),
    country: Yup.string()
      .min(2, translation.signUpShortError)
      .max(127, translation.signUpLongError)
      .required(translation.required),
  });

  useEffect(() => {
    if (state?.show) {
      setShow(true);
    }
  }, []);

  return (
    <>
      {selectedTenant != null && tenants && tenants[selectedTenant] && (
        <>
          <Grid container>
            <Grid item md={6}>
              <Paper sx={{ p: 2 }}>
                <Formik
                  initialValues={{
                    companyName: tenants[selectedTenant].companyName,
                    companyLegalName: tenants[selectedTenant].companyLegalName,
                    addressLine1: tenants[selectedTenant].addressLine1,
                    addressLine2: tenants[selectedTenant].addressLine2,
                    city: tenants[selectedTenant].city,
                    state: tenants[selectedTenant].state,
                    zipCode: tenants[selectedTenant].zipCode,
                    country: tenants[selectedTenant].country,
                    shareCapital: tenants[selectedTenant].shareCapital,
                    vatNumber: tenants[selectedTenant].vatNumber,
                    regNo: tenants[selectedTenant].regNo,
                    otherId: tenants[selectedTenant].otherId,
                    taxNumber: tenants[selectedTenant].taxNumber,
                  }}
                  validationSchema={companySchema}
                  enableReinitialize
                  onSubmit={(values, { setSubmitting }) => {
                    const responsePromise = updateTenant(
                      {
                        ...values,
                      },
                      tenants[selectedTenant].id
                    );

                    responsePromise
                      .then(() => {
                        setSuccessMessage(translation.savedMessage);
                        setSubmitting(false);
                        setUpdateTrigger(!updateTrigger);
                      })
                      .catch((error) => {
                        setErrorMessage(
                          error.response.data["hydra:description"]
                        );
                        setSubmitting(false);
                      });
                  }}
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <form noValidate className="" onSubmit={handleSubmit}>
                      <Stack spacing={2}>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.companyName}
                                name="companyName"
                              />
                            </Stack>
                          </Box>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.companyLegalName}
                                name="companyLegalName"
                              />
                            </Stack>
                          </Box>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.tenant.vatNumber}
                                name="vatNumber"
                              />
                            </Stack>
                          </Box>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.tenant.taxNumber}
                                name="taxNumber"
                              />
                            </Stack>
                          </Box>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.tenant.regNo}
                                name="regNo"
                              />
                            </Stack>
                          </Box>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.tenant.shareCapital}
                                name="shareCapital"
                              />
                            </Stack>
                          </Box>
                        </Stack>
                        <Box flexGrow={1}>
                          <Stack>
                            <FormTextField
                              label={translation.tenant.otherId}
                              name="otherId"
                            />
                          </Stack>
                        </Box>
                        <FormTextField
                          label={translation.addressLine1}
                          name="addressLine1"
                        />
                        <FormTextField
                          label={translation.addressLine2}
                          name="addressLine2"
                        />

                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.city}
                                name="city"
                              />
                            </Stack>
                          </Box>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.state}
                                name="state"
                              />
                            </Stack>
                          </Box>
                        </Stack>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems={"flex-end"}
                        >
                          <FormTextField
                            label={translation.zipCode}
                            name="zipCode"
                          />
                          <AutocompleteCountryField
                            controlId="country01"
                            label={translation.country}
                            type="text"
                            name="country"
                            options={countryList}
                            placeholder={false}
                          />
                        </Stack>
                        <Box display={"flex"} justifyContent={"flex-end"}>
                          <LoadingButton
                            type="submit"
                            loading={isSubmitting}
                            variant="contained"
                          >
                            {translation.updateCompanyButton}
                          </LoadingButton>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Paper>
            </Grid>
            <Grid item md={6}>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Box sx={{ mb: 4 }}>
                  <Button
                    variant={"contained"}
                    startIcon={<Add />}
                    onClick={() => navigate(routeNames.newCompany)}
                  >
                    {translation.createNewCompanyButton}
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => setOpenDelete(true)}
              variant="outlined"
              color="warning"
            >
              <DeleteOutline></DeleteOutline>
              {translation.companyInfo.deleteCompany}
            </Button>
          </Box>
          <Dialog
            open={openDelete}
            onClose={() => {
              setOpenDelete(false);
              setDeleteCompanyName("");
            }}
            aria-labelledby="alert-dialog-title-confirm-delete"
            aria-describedby="alert-dialog-confirm-delete"
          >
            <DialogTitle id="alert-dialog-title-confirm-delete">
              {translation.companyInfo.deleteCompany}
            </DialogTitle>
            <DialogContent>
              <Typography>
                {translation.companyInfo.deleteCompanyMessage}
              </Typography>
              <Typography fontWeight={700}>
                {tenants[selectedTenant].companyName}
              </Typography>
              <Typography>
                {translation.companyInfo.deleteCompanyTypeName}
              </Typography>
              <Box sx={{ mt: 4 }}>
                <TextField
                  variant="standard"
                  onChange={(event) => setDeleteCompanyName(event.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant={"outlined"}
                onClick={() => {
                  setDeleteCompanyName("");
                  setOpenDelete(false);
                }}
              >
                {translation.closeButton}
              </Button>
              <LoadingButton
                variant={"contained"}
                loading={loading}
                disabled={
                  tenants[selectedTenant].companyName !== deleteCompanyName
                }
                onClick={() => {
                  if (tenants[selectedTenant]) {
                    setLoading(true);
                    deleteTenant(tenants[selectedTenant].id)
                      .then(() => {
                        setOpenDelete(false);
                        setLoading(false);
                        setSuccessMessage(
                          translation.companyInfo.deletedCompany
                        );
                        setUpdateTrigger(
                          (updateTrigger: boolean) => !updateTrigger
                        );
                        setDeleteCompanyName("");
                      })
                      .catch((e) => {
                        setLoading(false);
                        setOpenDelete(false);
                        setErrorMessage(e.response.data["hydra:description"]);
                        setDeleteCompanyName("");
                      });
                  }
                }}
              >
                {translation.confirmDelete}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      )}

      <NewCompanyCongratulations show={show} setShow={setShow} />
    </>
  );
};

export default CompanyInfo;
