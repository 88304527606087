import axios from "helpers/axios";
import { backendURL } from "config";
import { IDnsZone, IDnsRecord } from "interfaces";

export async function getDnsZone(zoneId: string) {
    const URL = backendURL + "/dns_zones/" + zoneId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => error);
    return res;
}

export async function getDnsZonesByTenant(tenantId: string) {
    const URL = backendURL + "/dns_zones?tenant.id=" + tenantId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => error);
    return res;
}

export async function newDnsZone(dnsZone: IDnsZone) {
    const URL = backendURL + "/dns_zones";
    const res = axios
        .post(URL, dnsZone)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deleteDnsZone(dnsZone: IDnsZone) {
    const URL = backendURL + "/dns_zones/" + dnsZone.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function newActivationCheck(zoneId: string) {
    const URL = backendURL + zoneId + "/new_activation_check";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function getZoneStatus(zoneId: string) {
    const URL = backendURL + zoneId + "/status_check";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function newDnsRecord(dnsRecord: IDnsRecord) {
    const URL = backendURL + "/dns_records";
    const res = axios
        .post(URL, dnsRecord)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function newEmailDnsRecord(dnsZone: IDnsZone) {
    const URL = backendURL + "/dns_zones/" + dnsZone.id + "/create_ses_identity";
    const res = axios
        .post(URL, dnsZone)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateDnsRecord(dnsRecord: IDnsRecord) {
    const URL = backendURL + "/dns_records/" + dnsRecord.id;
    const res = axios
        .put(URL, dnsRecord)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deleteDnsRecord(dnsRecord: IDnsRecord) {
    const URL = backendURL + "/dns_records/" + dnsRecord.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}
