import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import { deleteDnsZone } from "services/dns";
import { IDnsRecord, IDnsZone } from "interfaces";
import {
  Box,
  Stack,
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
} from "@mui/material";
import {
  newActivationCheck,
  getZoneStatus,
  deleteDnsRecord,
  newEmailDnsRecord,
} from "services/dns";
import { Add, Delete } from "@mui/icons-material";
import { recordTTLs } from "helpers/dnsRecordsData";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import InfoPopover from "components/InfoPopover";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import LoadingIcon from "components/Feedback/LoadingIcon";
import RecordForm from "./RecordForm";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import { errorMessage as errorMessageHandler } from "helpers";
import { LoadingButton } from "@mui/lab";

interface Data {
  id: string;
  type: string;
  name: string;
  value: string;
  ttl: string;
}

const headCells: HeadCell[] = [
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    sorting: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },
  {
    id: "value",
    numeric: false,
    disablePadding: false,
    label: "Value",
    sorting: false,
  },
  {
    id: "ttl",
    numeric: false,
    disablePadding: false,
    label: "TTL",
    sorting: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    sorting: false,
  },
];

const DnsZone = ({
  tenant,
  zone,
  updateTrigger,
  setUpdateTrigger,
}: {
  tenant: any;
  zone: IDnsZone;
  updateTrigger: boolean;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [dnsZone, setDnsZone] = useState<IDnsZone | null | undefined>(
    undefined
  );
  const { errorMessage, setErrorMessage, setSuccessMessage } =
    useSnackbarContext();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<Data[]>([]);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [records, setRecords] = useState<IDnsRecord[]>([]);
  const [showNewRecord, setShowNewRecord] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState<IDnsRecord | null>(null);
  const [checkSuccess, setCheckSuccess] = useState(false);
  const translation = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setDnsZone(zone);
  }, [tenant, zone]);

  useEffect(() => {
    if (dnsZone && dnsZone["@id"]) {
      if (dnsZone.status === "pending") {
        const checkInterval = setInterval(() => {
          if (dnsZone.status === "pending" && dnsZone["@id"]) {
            getZoneStatus(dnsZone["@id"]).then((response) => {
              if (response.data.status === "active") {
                setDnsZone({
                  ...dnsZone,
                  status: "active",
                });
                clearInterval(checkInterval);
              }
            });
          }
        }, 60000);
        return function cleanup() {
          clearInterval(checkInterval);
        };
      }
    }
  }, [dnsZone]);

  const activationCheck = () => {
    if (dnsZone && dnsZone["@id"]) {
      newActivationCheck(dnsZone["@id"])
        .then((response) => {
          if (response.data.status === true) {
            setCheckSuccess(true);
            setSuccessMessage(translation.dnsZone.newCloudflareCheckSuccess);
          } else {
            setErrorMessage(translation.unkownError);
          }
        })
        .catch((e) => setErrorMessage(errorMessageHandler(e)));
    }
  };

  const setUpEmail = async () => {
    setIsLoading(true);
    if (dnsZone && dnsZone["@id"]) {
      await newEmailDnsRecord(dnsZone);
      window.location.reload();
    }
    setIsLoading(false);
  };

  const removeDnsRecord = (dnsRecord: IDnsRecord) => {
    setIsLoading(true);
    deleteDnsRecord(dnsRecord)
      .then((response) => {
        setSuccessMessage(translation.deletedMessage);
        setIsLoading(false);
      })
      .catch((e) => {
        setErrorMessage(errorMessageHandler(e));
        setIsLoading(false);
      });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const recordsToData = (records: IDnsRecord[]) => {
    records.sort((a, b) => {
      if (a.id && b.id) {
        return a.id - b.id;
      }
      return 0;
    });
    const data = records.map((record: IDnsRecord) => {
      return {
        id: record["@id"] ? record["@id"] : "",
        type: record.type ? record.type : "",
        name: record.name ? record.name : "",
        value: record.value ? record.value : "",
        ttl: record.ttl
          ? recordTTLs[record.ttl as keyof typeof recordTTLs]
          : "",
      };
    });

    return data;
  };

  useEffect(() => {
    if (dnsZone?.dnsRecords) {
      setRecords(dnsZone.dnsRecords);
      setRows(recordsToData(dnsZone.dnsRecords));
    }
  }, [dnsZone, updateTrigger]);

  return (
    <Box>
      {dnsZone && (
        <>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack>
              <Typography variant="h5">{dnsZone.domainName}</Typography>
              {dnsZone.type == "managed" && (
                <Typography>
                  {translation.dnsZone.status + ": " + dnsZone.status}
                </Typography>
              )}
            </Stack>
            <Box>
              <LoadingButton
                onClick={() => setOpenDelete(true)}
                variant="outlined"
                color="warning"
                loading={isLoading}
              >
                <Delete></Delete>
                {translation.dnsZone.deleteDnsZone}
              </LoadingButton>
            </Box>
          </Stack>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {dnsZone.type == "managed" && (
            <>
              <Stack spacing={3}>
                <Stack direction="row" spacing={6}>
                  <Stack spacing={1}>
                    <Typography variant="h5">
                      {translation.dnsZone.nameServers}
                    </Typography>
                    <Stack>
                      {dnsZone.nameServers &&
                        dnsZone.nameServers.map((nameServer, index) => (
                          <Typography
                            variant="body2"
                            key={"nameserver-" + index}
                          >
                            {nameServer}
                          </Typography>
                        ))}
                    </Stack>
                    {dnsZone.status === "pending" && (
                      <Box>
                        <Button
                          variant="contained"
                          onClick={activationCheck}
                          disabled={errorMessage !== "" || checkSuccess}
                        >
                          {translation.dnsZone.checkNameserversButton}
                        </Button>
                      </Box>
                    )}
                  </Stack>
                  <Stack spacing={1}>
                    <Typography variant="h5">
                      {translation.dnsZone.mailDomainTitle}
                    </Typography>
                    {dnsZone.dnsRecords?.filter(
                      (dnsRecord: IDnsRecord) =>
                        dnsRecord.serviceType === "email"
                    ).length === 0 ? (
                      <Stack
                        sx={{ height: "100%" }}
                        justifyContent={"space-between"}
                      >
                        <Typography>
                          {translation.dnsZone.mailNotSet}
                        </Typography>
                        <Box>
                          <Stack direction={"row"} spacing={2}>
                            <Button
                              variant="contained"
                              disabled={
                                isLoading || dnsZone.status === "pending"
                              }
                              onClick={setUpEmail}
                            >
                              {translation.dnsZone.setEmailButton}
                            </Button>
                            {dnsZone.status === "pending" && (
                              <InfoPopover
                                infoContent={
                                  translation.dnsZone.setUpNameServersFirst
                                }
                              ></InfoPopover>
                            )}
                          </Stack>
                        </Box>
                      </Stack>
                    ) : (
                      <Box>
                        <Stack spacing={1}>
                          {dnsZone.dnsRecords
                            ?.filter(
                              (dnsRecord: IDnsRecord) =>
                                dnsRecord.serviceType === "email"
                            )
                            .map((dnsRecord: IDnsRecord, index) => (
                              <Stack
                                direction="row"
                                spacing={2}
                                flexWrap={"wrap"}
                                key={"custom-domain-email-" + index}
                              >
                                <Typography>{dnsRecord.name}</Typography>
                                <Typography>{dnsRecord.value}</Typography>
                              </Stack>
                            ))}
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography variant="h5">
                    {translation.dnsZone.records}
                  </Typography>
                  <Box>
                    <Button
                      variant="contained"
                      startIcon={<Add></Add>}
                      onClick={() => {
                        setShowNewRecord(true);
                      }}
                    >
                      {translation.dnsZone.addRecord}
                    </Button>
                  </Box>
                </Stack>

                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      headCells={headCells}
                      numSelected={1}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {!isLoading ? (
                        rows.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row.id}
                              style={{
                                height: 100,
                              }}
                            >
                              <TableCell component="th">
                                <Box>{row.type}</Box>
                              </TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    width: 500,
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {row.value}
                                </Box>
                              </TableCell>
                              <TableCell>{row.ttl}</TableCell>
                              <TableCell>
                                <Stack direction="row" spacing={1}>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      setShowNewRecord(true);
                                      setRecordToEdit(records[index]);
                                    }}
                                  >
                                    {translation.editButton}
                                  </Button>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow
                          style={{
                            height: 100,
                          }}
                        >
                          <TableCell colSpan={6}>
                            <Stack alignItems="center">
                              <LoadingIcon />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </>
          )}
          {dnsZone.type == "customer" && (
            <Grid item md={12}>
              <Stack spacing={3}>
                <Typography variant="h5">
                  {translation.dnsZone.customDomainsTitle}
                </Typography>
                {dnsZone.dnsRecords?.filter(
                  (dnsRecord: IDnsRecord) => dnsRecord.serviceType === "app"
                ).length === 0 ? (
                  <Typography>{translation.dnsZone.noCustomDomains}</Typography>
                ) : (
                  <Box>
                    <Stack spacing={1}>
                      {dnsZone.dnsRecords
                        ?.filter(
                          (dnsRecord: IDnsRecord) =>
                            dnsRecord.serviceType === "app"
                        )
                        .map((dnsRecord: IDnsRecord, index) => (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            flexWrap={"wrap"}
                            key={"custom-domain-" + index}
                          >
                            <Typography>{dnsRecord.name}</Typography>
                            <Typography>{dnsRecord.value}</Typography>
                            <IconButton
                              color="warning"
                              disabled={isLoading}
                              size="small"
                              onClick={() => removeDnsRecord(dnsRecord)}
                            >
                              <Delete />
                            </IconButton>
                          </Stack>
                        ))}
                    </Stack>
                  </Box>
                )}
                <Stack spacing={3}>
                  <Typography variant="h5">
                    {translation.dnsZone.mailDomainTitle}
                  </Typography>
                  {dnsZone.dnsRecords?.filter(
                    (dnsRecord: IDnsRecord) => dnsRecord.serviceType === "email"
                  ).length === 0 ? (
                    <>
                      <Typography>{translation.dnsZone.mailNotSet}</Typography>
                      <Box>
                        <Stack direction={"row"} spacing={2}>
                          <Button
                            variant="contained"
                            disabled={isLoading || dnsZone.status === "pending"}
                            onClick={setUpEmail}
                          >
                            {translation.dnsZone.setEmailButton}
                          </Button>
                          {dnsZone.status === "pending" && (
                            <InfoPopover
                              infoContent={
                                translation.dnsZone.setUpNameServersFirst
                              }
                            ></InfoPopover>
                          )}
                        </Stack>
                      </Box>
                    </>
                  ) : (
                    <Box>
                      <Stack spacing={1}>
                        {dnsZone.dnsRecords
                          ?.filter(
                            (dnsRecord: IDnsRecord) =>
                              dnsRecord.serviceType === "email"
                          )
                          .map((dnsRecord: IDnsRecord, index) => (
                            <Stack
                              direction="row"
                              spacing={2}
                              flexWrap={"wrap"}
                              key={"custom-domain-email-" + index}
                            >
                              <Typography>{dnsRecord.name}</Typography>
                              <Typography>{dnsRecord.value}</Typography>
                            </Stack>
                          ))}
                      </Stack>
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Grid>
          )}

          <Box display="flex" justifyContent="flex-end"></Box>
          <RecordForm
            showNewRecord={showNewRecord}
            setShowNewRecord={setShowNewRecord}
            recordToEdit={recordToEdit}
            setRecordToEdit={setRecordToEdit}
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            domain={dnsZone}
          />
        </>
      )}

      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title-confirm-delete"
        aria-describedby="alert-dialog-confirm-delete"
      >
        <DialogTitle id="alert-dialog-title-confirm-delete">
          {translation.dnsZone.deleteDnsZone}
        </DialogTitle>
        <DialogContent>
          <Typography>{translation.dnsZone.deleteZoneMessage}</Typography>
          <Typography fontWeight={700}>{dnsZone?.domainName}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={() => setOpenDelete(false)}>
            {translation.closeButton}
          </Button>
          <LoadingButton
            loading={isLoading}
            variant={"contained"}
            onClick={() => {
              if (dnsZone) {
                deleteDnsZone(dnsZone)
                  .then(() => {
                    setOpenDelete(false);
                    setUpdateTrigger((updateTrigger: any) => !updateTrigger);
                    setSuccessMessage(translation.dnsZone.deletedDnsZone);

                    navigate(routeNames.infrastructure);
                  })
                  .catch((e) => {
                    setOpenDelete(false);
                    setErrorMessage(e.response.data["hydra:description"]);
                  });
              }
            }}
          >
            {translation.confirmDelete}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DnsZone;
