import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { Box, Stack, Typography } from "@mui/material";

import useTranslation from "components/customHooks/translations";
import FormNumberField from "../FormNumberField";
import InfoPopover from "components/InfoPopover";
import { useUserContext } from "components/contexts/UserContext";
import FormTextField from "../FormTextField/FormTextField";
import DateTimePickerField from "../DateTimePickerField";
import SwitchField from "../SwitchField/SwitchField";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { IVariation } from "interfaces";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  max: number | undefined;
}

const NumericFloatFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, max, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      max={max}
      allowNegative={false}
      decimalScale={2}
      valueIsNumericString
    />
  );
});

const ArrayVariationsField = ({
  label,
  name,
}: {
  label?: string;
  name: string;
}) => {
  const translation = useTranslation();
  const { selectedTenant, tenants } = useUserContext();
  const [field, _meta, helper] = useField(name);
  const [fieldStock] = useField("hasStock");

  return (
    <>
      <Stack spacing={2}>
        <Typography>{label}</Typography>
        {field.value.map((value: IVariation, index: number) => {
          return (
            <Stack
              direction={"row"}
              key={"variation-" + index}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={2}
              flexWrap={"wrap"}
            >
              <TextField
                variant="standard"
                label={translation.products.variationNameLabel}
                value={value.name}
                onChange={(e) => {
                  const oldValues = field.value.map((v: any) => {
                    return { ...v };
                  });
                  oldValues[index].name = e.target.value;
                  if (index === oldValues.length - 1) {
                    oldValues.push({
                      name: "",
                      sku: "",
                      stockCount: "",
                      hasDiscount: false,
                      netPrice: "",
                      grossPrice: "",
                      barcode: "",
                    });
                  }
                  if (e.target.value === "") {
                    oldValues.splice(index, 1);
                  }
                  helper.setValue([...oldValues]);
                }}
              />

              <FormNumberField
                type="int"
                label={translation.products.stockCountField}
                name={"variations[" + index + "].stockCount"}
                disabled={!fieldStock.value}
              />

              <Stack direction="row" spacing={1} alignItems={"flex-end"}>
                <FormNumberField
                  type="float"
                  label={translation.products.priceField}
                  name={
                    tenants[selectedTenant].settings.taxIncluded
                      ? "variations[" + index + "].netPrice"
                      : "variations[" + index + "].grossPrice"
                  }
                />
                <Box>
                  <InfoPopover
                    infoContent={translation.products.variationOptionalField}
                  />
                </Box>
              </Stack>
              <>
                <SwitchField
                  name={"variations[" + index + "].hasDiscount"}
                  label={translation.products.discoutLabel}
                ></SwitchField>

                {value.hasDiscount && (
                  <Stack spacing={2}>
                    <Box>
                      <TextField
                        onChange={(e) => {
                          const oldValues = field.value.map((v: any) => {
                            return {
                              ...v,
                              productDiscount: { ...v.productDiscount },
                            };
                          });
                          if (
                            !oldValues[index].productDiscount &&
                            e.target.value != ""
                          ) {
                            oldValues[index].productDiscount = {
                              netPrice: "",
                              grossPrice: "",
                              startDate: "",
                              endDate: "",
                            };
                          }
                          if (e.target.value == "") {
                            tenants[selectedTenant].settings.taxIncluded
                              ? (oldValues[index].productDiscount.netPrice = "")
                              : (oldValues[index].productDiscount.grossPrice =
                                  "");
                            helper.setValue([...oldValues]);

                            return;
                          }
                          const oldPrice = tenants[selectedTenant].settings
                            .taxIncluded
                            ? oldValues[index].netPrice
                            : oldValues[index].grossPrice;
                          const salePrice = parseFloat(e.target.value);

                          // const discountPercentage = "";

                          tenants[selectedTenant].settings.taxIncluded
                            ? (oldValues[index].productDiscount.netPrice =
                                salePrice)
                            : (oldValues[index].productDiscount.grossPrice =
                                salePrice);

                          helper.setValue([...oldValues]);
                        }}
                        label={translation.products.productDiscountPriceField}
                        InputProps={{
                          inputComponent: NumericFloatFormatCustom as any,
                        }}
                        variant="standard"
                        value={
                          value.productDiscount
                            ? tenants[selectedTenant].settings.taxIncluded
                              ? value.productDiscount.netPrice
                              : value.productDiscount.grossPrice
                            : ""
                        }
                        // error={
                        //   tenants[selectedTenant].settings.taxIncluded
                        //     ? errors.productDiscount
                        //       ? !!errors.productDiscount.netPrice
                        //       : false
                        //     : errors.productDiscount
                        //     ? !!errors.productDiscount.grossPrice
                        //     : false
                        // }
                      />
                    </Box>

                    <Box>
                      <DateTimePickerField
                        name={
                          "variations[" + index + "].productDiscount.startDate"
                        }
                        label={translation.products.discountStartDateField}
                        disablePast
                      ></DateTimePickerField>
                    </Box>
                    <Box>
                      <DateTimePickerField
                        name={
                          "variations[" + index + "].productDiscount.enDate"
                        }
                        disablePast
                        label={translation.products.discountEndDateField}
                      ></DateTimePickerField>
                    </Box>
                  </Stack>
                )}
              </>
              <Stack direction="row" spacing={1} alignItems={"flex-end"}>
                <FormTextField
                  type="float"
                  label={translation.products.skuField}
                  name={"variations[" + index + "].sku"}
                />
                <Box>
                  <InfoPopover
                    infoContent={translation.products.variationOptionalField}
                  />
                </Box>
              </Stack>
              <Stack direction="row" spacing={1} alignItems={"flex-end"}>
                <FormTextField
                  type="float"
                  label={translation.products.barcodeLabel}
                  name={"variations[" + index + "].barcode"}
                />
                <Box>
                  <InfoPopover
                    infoContent={translation.products.variationOptionalField}
                  />
                </Box>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default ArrayVariationsField;
