import * as React from "react";
import { Link } from "react-router-dom";
import { routeNames } from "routes";
import useTranslation from "components/customHooks/translations";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormTextField from "components/Form/FormTextField/FormTextField";
import { backendURL } from "config";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { logout } from "services/users";
import { requestPasswordReset, passwordReset } from "services/users";
import { Button, Paper, Stack } from "@mui/material";

const PasswordReset = (props: any) => {
  const { state }: any = useLocation();
  const translation = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState("");

  const PasswordResetRequestSchema = Yup.object().shape({
    email: Yup.string()
      .email(translation.signUpEmailError)
      .required(translation.required),
  });

  const PasswordResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, translation.signUpShortError)
      .required(translation.required),
  });

  useEffect(() => {
    // Update the document title using the browser API
    const token = searchParams.get("token");
    if (token) {
      setToken(token);
    }
  }, []);

  const sendToLogIn = () => {
    logout().then(() => (window.location.href = routeNames.home));
  };

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={4}
      sx={{ height: "100vh" }}
    >
      <Paper sx={{ p: 2 }} elevation={3}>
        {token === "" ? (
          error === "" ? (
            success === false ? (
              <>
                <h2 className="mb-3" data-testid="title">
                  {translation.passwordResetHeader}
                </h2>
                <p className="mb-3">{translation.passwordResetMessage}</p>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={PasswordResetRequestSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    requestPasswordReset(values.email)
                      .then((res) => {
                        setSubmitting(false);
                        if (res.status === 200) {
                          setSuccess(true);
                        }
                      })
                      .catch((e: any) => {
                        setError(translation.passwordResetRequestError);
                      });
                  }}
                >
                  {({
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    isSubmitting,
                  }) => (
                    <form
                      noValidate
                      className="sign-in-form"
                      onSubmit={handleSubmit}
                    >
                      <FormTextField
                        label={translation.signUpEmailLabel}
                        name="email"
                      />

                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-sign-up"
                      >
                        {translation.passwordResetRequestButton}
                      </button>
                    </form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <h2 className="mb-3" data-testid="title">
                  {translation.passwordResetHeader}
                </h2>
                <p className="mb-3" data-testid="title">
                  {translation.passwordResetRequestSuccess}
                </p>
              </>
            )
          ) : (
            <>
              <h2 className="mb-3" data-testid="title">
                {error}
              </h2>
            </>
          )
        ) : resetError === "" ? (
          resetSuccess === false ? (
            <>
              <h2 className="mb-3" data-testid="title">
                {translation.passwordResetHeader}
              </h2>
              <p className="mb-3">{translation.passwordResetFormMessage}</p>
              <Formik
                initialValues={{
                  password: "",
                }}
                validationSchema={PasswordResetSchema}
                onSubmit={(values, { setSubmitting }) => {
                  passwordReset(token, values.password)
                    .then((res) => {
                      setSubmitting(false);
                      if (res.status === 200) {
                        setResetSuccess(true);
                      }
                    })
                    .catch((e: any) => {
                      setResetError(translation.passwordResetError);
                    });
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <form
                    noValidate
                    className="sign-in-form"
                    onSubmit={handleSubmit}
                  >
                    <FormTextField
                      label={translation.signUpPasswordLabel}
                      name="password"
                      type="password"
                    />

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-sign-up"
                    >
                      {translation.passwordResetRequestButton}
                    </button>
                  </form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <h2 className="mb-3" data-testid="title">
                {translation.passwordResetHeader}
              </h2>
              <p className="mb-3" data-testid="title">
                {translation.passwordResetSuccess}
              </p>
              <Button onClick={sendToLogIn}>{translation.signInButton}</Button>
            </>
          )
        ) : (
          <>
            <h2 className="mb-3" data-testid="title">
              {resetError}
            </h2>
          </>
        )}
      </Paper>
    </Stack>
  );
};

export default PasswordReset;
