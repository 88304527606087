import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { routeNames } from "routes";
import useTranslation from "components/customHooks/translations";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { backendURL } from "config";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { logout } from "services/users";
import { Paper, Stack, Typography, Button, Box } from "@mui/material";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { LoadingButton } from "@mui/lab";
import { getAnafAuthUrl } from "services/users";

const EFacturaAuth = (props: any) => {
  const { state }: any = useLocation();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [resending, setResending] = useState(false);
  const [resendError, setResendError] = useState("");
  const [resendSuccess, setResendSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [authUrl, setAuthUrl] = useState("");
  const { key, id } = useParams();

  useEffect(() => {
    if (key && id) {
      localStorage.setItem("efacturaKey", key);
      localStorage.setItem("efacturaId", id);
      setLoading(true);
      getAnafAuthUrl().then((res) => {
        setAuthUrl(res.data.url);
        setLoading(false);
      });
    }
  }, []);

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      sx={{
        height: "100vh",
      }}
    >
      <Paper sx={{ p: 4 }}>
        <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
          <Typography variant="h5">
            {translation.efactura.connectToAnaf}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(authUrl, "_blank")}
          >
            {translation.efactura.loginToAnaf}
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default EFacturaAuth;
