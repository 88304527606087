import axios from "helpers/axios";
import axios2 from "axios";  

import { backendURL } from "config";
import {
  IAddress,
  IApp,
  IAppIntegrations,
  IBrandBook,
  IBrandBookLocale,
  IConsent,
  IConsentLocale,
  IContact,
  ICoupon,
  ICourier,
  ICro,
  ICroLocale,
  ICustomer,
  IEfactura,
  IImageObject,
  IInvoiceSeries,
  IInvoiceTemplate,
  INewsletterSubscriber,
  IOrder,
  ISettings,
  IShippingMethod,
  ITax,
} from "interfaces";

export async function createNewTenant(tenant: {
  companyName: string;
  companyLegalName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  users: Array<string>;
}) {
  const URL = backendURL + "/tenants";

  const res = await axios.post(
    URL,
    JSON.stringify({
      ...tenant,
    })
  );

  return res;
}

export async function deleteTenant(tenantId: string) {
  const URL = backendURL + "/tenants/" + tenantId;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPaymentOptionsByTenant(tenantId: string) {
  const URL = backendURL + "/payments?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPaymentOptionById(paymentId: string) {
  const URL = backendURL + "/payments/" + paymentId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPaymentOptionLocales(paymentId: string) {
  const URL = backendURL + "/payments/" + paymentId + "/payment_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getAvailablePaymentOptionsByTenant(tenantId: string) {
  const URL = backendURL + "/payments?isActive=true&tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCustomersByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10
) {
  const URL =
    backendURL +
    "/customers?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function getCustomer(customerId: string) {
  const URL = backendURL + "/customers/" + customerId;

  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function createCustomer(tenantId: string, customer: ICustomer) {
  const URL = backendURL + "/register";
  const $tenantUri = "/tenants/" + tenantId;
  const res = axios
    .post(
      URL,
      {
        ...customer,
        tenants: [$tenantUri],
        anonymous: true,
      },
      {
        headers: {
          "X-Tenant-ID": tenantId,
        },
      }
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createAddress(address: IAddress) {
  const URL = backendURL + "/addresses";
  const res = axios
    .post(URL, address)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateAddress(address: IAddress) {
  const URL = backendURL + "" + address["@id"];
  const res = axios
    .patch(URL, address)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getInvoicesByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc",
  statusFilter = ""
) {
  const filterQuery = statusFilter !== "" ? "&status=" + statusFilter : "";
  const URL =
    backendURL +
    "/invoices?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order +
    filterQuery;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}
export async function getInvoiceTemplatesByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc",
  recurrent = false
) {
  const URL =
    backendURL +
    "/invoice_templates?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order +
    "&recurrent=" +
    recurrent;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createInvoiceTemplate(invoiceTemplate: IInvoiceTemplate) {
  const URL = backendURL + "/invoice_templates";
  const res = axios
    .post(URL, invoiceTemplate)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getInvoiceTemplate(invoiceTemplateId: string) {
  const URL = backendURL + "/invoice_templates/" + invoiceTemplateId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateInvoiceTemplate(invoiceTemplate: IInvoiceTemplate) {
  const URL = backendURL + "/invoice_templates/" + invoiceTemplate.id;
  const res = axios
    .patch(URL, invoiceTemplate)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteInvoiceTemplate(invoiceTemplate: IInvoiceTemplate) {
  const URL = backendURL + "/invoice_templates/" + invoiceTemplate.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getInvoiceSeriesByTenant(tenantId: string) {
  const URL = backendURL + "/invoice_series?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createInvoiceSeries(invoiceSeries: IInvoiceSeries) {
  const URL = backendURL + "/invoice_series";
  const res = axios
    .post(URL, invoiceSeries)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateInvoiceSeries(invoiceSeries: IInvoiceSeries) {
  const URL = backendURL + "/invoice_series/" + invoiceSeries.id;
  const res = axios
    .patch(URL, invoiceSeries)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteInvoiceSeries(invoiceSeries: IInvoiceSeries) {
  const URL = backendURL + "/invoice_series/" + invoiceSeries.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getShippingMethodsByTenant(tenantId: string) {
  const URL = backendURL + "/shipping_methods?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getShippingMethod(id: string) {
  const URL = backendURL + "/shipping_methods/" + id;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getShippingMethodLocales(id: string) {
  const URL =
    backendURL + "/shipping_methods/" + id + "/shipping_method_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createShippingMethod(shippingMethod: IShippingMethod) {
  const URL = backendURL + "/shipping_methods";
  const res = axios
    .post(URL, shippingMethod)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateShippingMethod(shippingMethod: IShippingMethod) {
  const URL = backendURL + "/shipping_methods/" + shippingMethod.id;
  const res = axios
    .patch(URL, shippingMethod)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createShippingMethodLocale(shippingMethod: any) {
  const URL = backendURL + "/shipping_method_locales";
  const res = axios
    .post(URL, shippingMethod)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateShippingMethodLocale(shippingMethod: any) {
  const URL = backendURL + "/shipping_method_locales/" + shippingMethod.id;
  const res = axios
    .patch(URL, shippingMethod)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteShippingMethod(shippingMethod: IShippingMethod) {
  const URL = backendURL + "/shipping_methods/" + shippingMethod.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPolicyTemplates() {
  const URL = backendURL + "/policies/get/templates";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPoliciesByTenant(tenantId: string) {
  const URL = backendURL + "/policies?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}
export async function getPoliciesLocales(policyId: number) {
  const URL = backendURL + "/policies/" + policyId + "/policy_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createSettings(settings: ISettings) {
  const URL = backendURL + "/settings";
  const res = axios
    .post(URL, settings)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateSettings(settings: ISettings) {
  const URL = backendURL + "/settings/" + settings.id;
  const res = axios
    .patch(URL, settings)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createTax(tax: ITax) {
  const URL = backendURL + "/taxes";
  const res = axios
    .post(URL, tax)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateTax(tax: ITax) {
  const URL = backendURL + "/taxes/" + tax.id;
  const res = axios
    .patch(URL, tax)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getTaxHasProducts(tax: ITax) {
  const URL = backendURL + "/taxes/" + tax.id + "/hasProducts";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getTaxesByTenant(tenantId: string) {
  const URL = backendURL + "/taxes?isActive=true&tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getNewsletterSubscribersByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc"
) {
  const URL =
    backendURL +
    "/newsletter_subscribers?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function searchNewsletterSubscribersByTenant(
  tenantId: string,
  search: string
) {
  const URL =
    backendURL +
    "/newsletter_subscribers?tenant.id=" +
    tenantId +
    "&email=" +
    search;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getContactEntriesByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc"
) {
  const URL =
    backendURL +
    "/contact_entries?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function searchContactEntriesByTenant(
  tenantId: string,
  search: string
) {
  const URL =
    backendURL + "/contact_entries?tenant.id=" + tenantId + "&email=" + search;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function exportNewsletterSubscribersByTenant(tenantId: string) {
  const URL =
    backendURL +
    "/subscribersExport?tenant.id=" +
    tenantId +
    "&pagination=false";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateNewsletterSubscriber(
  subscriber: INewsletterSubscriber
) {
  const URL = backendURL + "/newsletter_subscribers/" + subscriber.id;
  const res = axios
    .patch(URL, subscriber)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCouponsByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc"
) {
  const URL =
    backendURL +
    "/coupons?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCoupon(couponId: string) {
  const URL = backendURL + "/coupons/" + couponId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCoupon(coupon: ICoupon) {
  const URL = backendURL + "/coupons";
  const res = axios
    .post(URL, coupon)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCoupon(coupon: ICoupon) {
  const URL = backendURL + "/coupons/" + coupon.id;
  const res = axios
    .patch(URL, coupon)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createOrder(order: IOrder) {
  const URL = backendURL + "/orders";
  const res = axios
    .post(URL, order)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCourier(courier: ICourier) {
  const URL = backendURL + "/couriers";
  const res = axios
    .post(URL, courier)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCourier(courier: ICourier) {
  const URL = backendURL + "/couriers/" + courier.id;
  const res = axios
    .patch(URL, courier)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteCourier(courier: ICourier) {
  const URL = backendURL + "/couriers/" + courier.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getServicesByCourier(courier: ICourier) {
  const URL = backendURL + "/couriers/" + courier.id + "/services";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPickUpPointsByCourier(courier: ICourier) {
  const URL = backendURL + "/couriers/" + courier.id + "/pickup";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCouriersByTenant(tenantId: string) {
  const URL = backendURL + "/couriers?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getSettingsByTenant(tenantId: string) {
  const URL = backendURL + "/settings?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getDropTagsByTenant(tenantId: string) {
  const URL = backendURL + "/drop_tags?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getIntegrationsByTenant(tenantId: string) {
  const URL = backendURL + "/app_integrations?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createIntegrations(integrations: IAppIntegrations) {
  const URL = backendURL + "/app_integrations";
  const res = axios
    .post(URL, integrations)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateIntegrations(integrations: IAppIntegrations) {
  const URL = backendURL + "/app_integrations/" + integrations.id;
  const res = axios
    .patch(URL, integrations)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getConsentByTenant(tenantId: string) {
  const URL = backendURL + "/consents?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createConsent(consent: IConsent) {
  const URL = backendURL + "/consents";
  const res = axios
    .post(URL, consent)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateConsent(consent: IConsent) {
  const URL = backendURL + "/consents/" + consent.id;
  const res = axios
    .put(URL, consent)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createConsentLocale(consent: IConsentLocale) {
  const URL = backendURL + "/consent_locales";
  const res = axios
    .post(URL, consent)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateConsentLocale(consent: IConsentLocale) {
  const URL = backendURL + "/consent_locales/" + consent.id;
  const res = axios
    .patch(URL, consent)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getConsentLocales(consentId: string) {
  const URL = backendURL + "/consents/" + consentId + "/consent_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCroByTenant(tenantId: string) {
  const URL = backendURL + "/cros?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCro(cro: ICro) {
  const URL = backendURL + "/cros";
  const res = axios
    .post(URL, cro)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCro(cro: ICro) {
  const URL = backendURL + "/cros/" + cro.id;
  const res = axios
    .put(URL, cro)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCroLocale(cro: ICroLocale) {
  const URL = backendURL + "/cro_locales";
  const res = axios
    .post(URL, cro)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCroLocale(cro: ICroLocale) {
  const URL = backendURL + "/cro_locales/" + cro.id;
  const res = axios
    .patch(URL, cro)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCroLocales(croId: string) {
  const URL = backendURL + "/cros/" + croId + "/cro_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getContactByTenant(tenantId: string) {
  const URL = backendURL + "/contacts?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createContact(contact: IContact) {
  const URL = backendURL + "/contacts";
  const res = axios
    .post(URL, contact)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateContact(contact: IContact) {
  const URL = backendURL + "/contacts/" + contact.id;
  const res = axios
    .put(URL, contact)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getBrandBookByTenant(tenantId: string) {
  const URL = backendURL + "/brand_books?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createBrandBook(brandBook: IBrandBook) {
  const URL = backendURL + "/brand_books";
  const res = axios
    .post(URL, brandBook)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createImage(image: IImageObject) {
  const URL = backendURL + "/image_objects";
  const res = axios
    .post(URL, image)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateBrandBook(brandBook: IBrandBook) {
  const URL = backendURL + "/brand_books/" + brandBook.id;
  const res = axios
    .put(URL, brandBook)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createBrandBookLocale(cro: IBrandBookLocale) {
  const URL = backendURL + "/brand_book_locales";
  const res = axios
    .post(URL, cro)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateBrandBookLocale(cro: IBrandBookLocale) {
  const URL = backendURL + "/brand_book_locales/" + cro.id;
  const res = axios
    .patch(URL, cro)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getBrandBookLocales(croId: string) {
  const URL = backendURL + "/brand_books/" + croId + "/brand_book_locales";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getUserById(userId: string) {
  const URL = backendURL + "/users/" + userId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createPayment(
  tenantId: string,
  label: string,
  type: string,
  iban: string,
  description: string,
  info: string
) {
  const URL = backendURL + "/payments";
  const res = axios
    .post(URL, {
      tenant: "/tenants/" + tenantId,
      label: label,
      type: type,
      iban: iban,
      description: description,
      info: info,
      isActive: true,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updatePayment(payment: any) {
  const URL = backendURL + "/payments/" + payment.id;
  const res = axios
    .patch(URL, payment)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createPaymentLocale(payment: any) {
  const URL = backendURL + "/payment_locales";
  const res = axios
    .post(URL, payment)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updatePaymentLocale(payment: any) {
  const URL = backendURL + "/payment_locales/" + payment.id;
  const res = axios
    .patch(URL, payment)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deletePayment(payment: any) {
  const URL = backendURL + "/payments/" + payment.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createPolicy(
  name: string,
  slug: string,
  content: string,
  tenantId: string
) {
  const URL = backendURL + "/policies";
  const res = axios
    .post(URL, {
      tenant: "/tenants/" + tenantId,
      name: name,
      slug: slug,
      content: content,
      isActive: true,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updatePolicy(payment: any) {
  const URL = backendURL + "/policies/" + payment.id;
  const res = axios
    .patch(URL, payment)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createPolicyLocale(policy: any) {
  const URL = backendURL + "/policy_locales";
  const res = axios
    .post(URL, policy)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updatePolicyLocale(policy: any) {
  const URL = backendURL + "/policy_locales/" + policy.id;
  const res = axios
    .patch(URL, policy)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deletePolicy(payment: any) {
  const URL = backendURL + "/policies/" + payment.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function postLinkAccount(paymentId: string) {
  const URL = backendURL + "/stripe/link-account";

  const res = axios
    .post(URL, {
      paymentId: paymentId,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getAccountOnboardingStatus(paymentId: string) {
  const URL = backendURL + "/stripe/get-account";

  const res = axios
    .post(URL, {
      paymentId: paymentId,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getTenantsByUser(userId: string) {
  const URL = backendURL + "/tenants?users.id=" + userId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getAppsByTenantId(tenantId: string) {
  const URL = backendURL + "/apps?tenant.id=" + tenantId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getAppDeploymentStatus(appId: string) {
  const URL = backendURL + "/application/deployment";
  const res = axios
    .post(URL, {
      appId: appId,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deployApp(appId: string) {
  const URL = backendURL + "/application/deployApp";
  const res = axios
    .post(URL, {
      id: appId,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateApp(app: IApp) {
  const URL = backendURL + `/apps/${app.id}`;
  const res = axios
    .patch(URL, app)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateUser(
  user: {
    firstName: string;
    lastName: string;
    email: string;
    photo: any;
    nickname?: string;
  },
  userId: string
) {
  const URL = backendURL + "/users/" + userId;

  const res = axios
    .put(
      URL,
      JSON.stringify({
        ...user,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateTenant(
  tenant: {
    companyName: string;
    companyLegalName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
  },
  tenantId: string
) {
  const URL = backendURL + "/tenants/" + tenantId;

  const res = await axios
    .patch(
      URL,
      JSON.stringify({
        ...tenant,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export function searchCustomers(email: string, tenantId: string) {
  const URL =
    backendURL + "/customers?tenant.id=" + tenantId + "&email=" + email;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createEFactura(efactura: Omit<IEfactura, "id" | "@id" | "createdAt">) {
  const URL = backendURL + "/efacturas";
  
  const res = await axios
    .post(URL, JSON.stringify(efactura))
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getEFactura(id: string) {
  const URL = backendURL + "/efacturas/" + id;

  const res = await axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateEFactura(id: string, efactura: Partial<IEfactura>) {
  const URL = backendURL + "/efacturas/" + id;

  const res = await axios
    .patch(URL, JSON.stringify(efactura))
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteEFactura(id: string) {
  const URL = backendURL + "/efacturas/" + id;

  const res = await axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getEFacturasByTenant(tenantId: string) {
  const URL = backendURL + "/efacturas?tenant.id=" + tenantId;

  const res = await axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getEFacturasByKey(id: string, key: string) {
  const URL = backendURL + "/efacturas/" + id + "/key/" + key;

  const res = await axios2
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}



