import axios from "helpers/axios";
import { backendURL } from "config";
import { IDoTask } from "interfaces";

export async function getDoTask(doTaskId: string) {
    const URL = backendURL + doTaskId;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => error);
    return res;
}

export async function getDoTasks() {
    const URL = backendURL + "/do_tasks";
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => error);
    return res;
}

export async function getDoTasksByTenant(
    tenantId: string,
    doneFilter: boolean
) {
    const URL =
        backendURL + "/do_tasks?tenant.id=" + tenantId + "&done=" + doneFilter;
    const res = axios
        .get(URL)
        .then((res) => res)
        .catch((error) => error);
    return res;
}

export async function newDoTask(doTask: IDoTask) {
    const URL = backendURL + "/do_tasks";
    const res = axios
        .post(URL, doTask)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function deleteDoTask(doTask: IDoTask) {
    const URL = backendURL + "/do_tasks/" + doTask.id;
    const res = axios
        .delete(URL)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function updateDoTask(doTask: IDoTask) {
    const URL = backendURL + "/do_tasks/" + doTask.id;
    const res = axios
        .put(URL, doTask)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}

export async function patchDoTask(doTaskId: string, doTask: any) {
    const URL = backendURL + "/do_tasks/" + doTaskId;
    const res = axios
        .patch(URL, doTask)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });
    return res;
}
