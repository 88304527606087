import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { IDoTask } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { errorMessage, formattedDate } from "helpers";
import { TransitionGroup } from "react-transition-group";
import { dosCSV } from "helpers/exportDo";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "components/Feedback/ConfirmationDialog";
import TaskItem from "./TaskItem";
import invariant from "tiny-invariant";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { patchDoTask, updateDoTask } from "services/do";
import { getAveragePosition, getMaxPosition } from "./taskHelpers";
import { DO_BUFFER } from "./taskHelpers";
import {
  type Edge,
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
const TaskList = ({
  doTasks,
  title,
  toggleDoTask,
  setDoTaskToEdit,
  setShowNewDoTask,
  list,
  setUpdateTrigger,
  setAllDoTasks,
}: {
  doTasks: IDoTask[];
  title: string;
  toggleDoTask: (doTask: IDoTask) => void;
  setDoTaskToEdit: (doTask: IDoTask) => void;
  setShowNewDoTask: (show: boolean) => void;
  list: string;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  setAllDoTasks: React.Dispatch<React.SetStateAction<IDoTask[]>>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { loading, setLoading } = useLoadingContext();
  const { setErrorMessage } = useSnackbarContext();
  const translation = useTranslation();
  const [exportLoading, setExportLoading] = useState(false);
  const [exportedDos, setExportedDos] = useState<any>(dosCSV);
  const [exportOpen, setExportOpen] = useState(false);
  const csvLink = useRef<any>();
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return dropTargetForElements({
      element: el,
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),

      onDrop: ({ source, location }) => {
        const innerMost = location.current.dropTargets[0];
        if (!innerMost) {
          return;
        }
        const newList = innerMost.data.list as string;
        let newPosition = DO_BUFFER;
        if (innerMost.data.id !== undefined) {
          if (innerMost.data.id === source.data.id) {
            setIsDraggedOver(false);
            return;
          }
          const edge = extractClosestEdge(innerMost.data);
          if (innerMost.data.index === 0 && edge === "top") {
            newPosition = getMaxPosition(doTasks);
          } else if (
            innerMost.data.index === doTasks.length - 1 &&
            edge === "bottom"
          ) {
            newPosition = (innerMost.data.position as any) / 2;
          } else {
            newPosition = getAveragePosition(
              doTasks,
              innerMost.data.index as number,
              edge as any
            );
          }
        }
        const oldDoTasks = [...doTasks];
        setAllDoTasks((prevDoTasks) =>
          prevDoTasks
            .map((doTask) => {
              if (doTask.id === source.data.id) {
                return { ...doTask, list: newList, position: newPosition };
              }
              return doTask;
            })
            .sort((a: IDoTask, b: IDoTask) => b.position - a.position)
        );
        patchDoTask(source.data.id as string, {
          list: newList,
          position: newPosition,
        }).catch((e) => {
          setErrorMessage(errorMessage(e));
          setAllDoTasks(oldDoTasks);
        });

        setIsDraggedOver(false);
      },
      getData: () => ({ list }),
    });
  }, [list, doTasks]);

  const exportTasks = () => {
    const newExportedDos = [...exportedDos];
    setExportLoading(true);
    doTasks.forEach((doTask: any) => {
      newExportedDos.push([
        doTask.id,
        doTask.name,
        doTask.description ? doTask.description : "",
        doTask.dueDate ? doTask.dueDate : "",
        doTask.done ? doTask.done : "",
        doTask.doneDate ? doTask.doneDate : "",
        doTask.list ? doTask.list : "",
      ]);
    });
    setExportedDos(newExportedDos);
  };

  useEffect(() => {
    if (exportedDos.length > 1) {
      csvLink.current.link.click();
      setExportedDos(dosCSV);
    }
    setExportLoading(false);
    setExportOpen(false);
  }, [exportedDos]);

  const openExportDialog = () => {
    setExportOpen(true);
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">{title}</Typography>

        <Box sx={{ my: 2 }}>
          <LoadingButton
            size="small"
            loading={exportLoading}
            variant={"outlined"}
            disabled={doTasks.length === 0}
            onClick={() => openExportDialog()}
          >
            {translation.do.exportTaks}
          </LoadingButton>
          <CSVLink
            data={exportedDos}
            ref={csvLink as any}
            enclosingCharacter='"'
            filename="export-tasks.csv"
          ></CSVLink>
        </Box>
      </Stack>
      <Stack spacing={4}>
        <Paper
          sx={{
            p: "48px 24px 24px 24px",
            borderRadius: "0px 0px 0px 8px",
          }}
        >
          <Stack
            ref={ref}
            sx={{
              border: isDraggedOver
                ? "1px dashed cyan"
                : "1px solid transparent",
            }}
          >
            {doTasks.length > 0 ? (
              <TransitionGroup>
                {doTasks.map((doTask, index) => (
                  <Collapse key={"list-" + doTask["@id"]}>
                    <TaskItem
                      doTask={doTask}
                      index={index}
                      toggleDoTask={toggleDoTask}
                      setDoTaskToEdit={setDoTaskToEdit}
                      setShowNewDoTask={setShowNewDoTask}
                    />
                    <Divider />
                  </Collapse>
                ))}
              </TransitionGroup>
            ) : loading ? (
              <Typography>{translation.loading}</Typography>
            ) : (
              <Typography>{translation.do.noDos}</Typography>
            )}
          </Stack>
        </Paper>
      </Stack>
      <ConfirmationDialog
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        title={translation.do.exportTasks}
        message={translation.do.exportTasksMessage}
        onConfirm={() => exportTasks()}
      />
    </Box>
  );
};

export default TaskList;
