import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  getBulkUploadProductsByTenant,
  patchBulkUploadProducts,
} from "services/products";

import { errorMessage, formattedDateTime } from "helpers";
import { IBulkUploadedRequest } from "interfaces";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";

interface Data {
  id: number;
  status: string;
  createdAt: string;
  errorMessage: string;
}

const headCells: HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Id",
    sorting: true,
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Date",
    sorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sorting: false,
  },
  {
    id: "errorMessage",
    numeric: false,
    disablePadding: false,
    label: "Message",
    sorting: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    sorting: false,
  },
];

const CsvImports = ({ updateTrigger }: { updateTrigger: boolean }) => {
  const { selectedTenant, tenants } = useUserContext();
  const [rowsCount, setRowsCount] = useState(0);
  const [_pagesNumber, setPagesNumber] = useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();

  const { loading, setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const [buttonLoading, setButtonLoading] = React.useState(false);
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, _setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dataToRows = (data: IBulkUploadedRequest[]) => {
    const rows = data.map((coupon: IBulkUploadedRequest) => {
      return {
        id: coupon.id ? coupon.id : 0,
        status: coupon.status,
        errorMessage: coupon.errorMessage ?? "",
        createdAt: formattedDateTime(coupon.createdAt),
      };
    });

    return rows;
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getBulkUploadProductsByTenant(
        tenants[selectedTenant].id,
        page + 1,
        rowsPerPage,
        orderBy,
        order
      )
        .then((res) => {
          setRows(dataToRows(res.data["hydra:member"]));
          setRowsCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [
    selectedTenant,
    tenants,
    page,
    rowsPerPage,
    orderBy,
    order,
    setLoading,
    updateTrigger,
  ]);

  useEffect(() => {
    if (rows.length > 0) {
      rows.forEach((row) => {
        if (row.status === "uploading") {
          setTimeout(() => {
            getBulkUploadProductsByTenant(
              tenants[selectedTenant].id,
              page + 1,
              rowsPerPage,
              orderBy,
              order
            )
              .then((res) => {
                res.data["hydra:member"].forEach(
                  (uploadRequest: IBulkUploadedRequest) => {
                    if (
                      uploadRequest.status === "draft" &&
                      row.id === uploadRequest.id
                    ) {
                      setSuccessMessage(
                        translation.productsExportImport.finishedCTA
                      );
                    }
                  }
                );
                setRows(dataToRows(res.data["hydra:member"]));
                setRowsCount(res.data["hydra:totalItems"]);
                setPagesNumber(
                  Math.ceil(res.data["hydra:totalItems"] / rowsPerPage)
                );
              })
              .catch((e) => setErrorMessage(errorMessage(e)));
          }, 10000);
        }
      });
    }
  }, [rows]);

  useEffect(() => {
    setPage(0);
  }, [selectedTenant]);

  return (
    <>
      <Box sx={{ my: 4 }}>
        <Typography variant="h5">
          {translation.productsExportImport.csvImports}
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        {rows.length > 0 ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {!loading ? (
                    rows.map((row) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            height: 100,
                          }}
                        >
                          <TableCell component="th">
                            <Box>{row.id}</Box>
                          </TableCell>
                          <TableCell>{row.createdAt}</TableCell>
                          <TableCell>
                            {row.status === "uploading" ? (
                              <CircularProgress />
                            ) : (
                              row.status
                            )}
                          </TableCell>
                          <TableCell>{row.errorMessage}</TableCell>
                          <TableCell>
                            {
                              <Stack direction={"row"} spacing={2}>
                                {(row.status === "done" ||
                                  row.status === "draft" ||
                                  row.status === "published") && (
                                  <Button
                                    variant={"outlined"}
                                    onClick={() => {
                                      navigate(
                                        "/products/export-import/images/" +
                                          row.id
                                      );
                                    }}
                                  >
                                    {
                                      translation.productsExportImport
                                        .uploadImages
                                    }
                                  </Button>
                                )}
                                {(row.status === "done" ||
                                  row.status === "draft") && (
                                  <LoadingButton
                                    variant={"contained"}
                                    loading={buttonLoading}
                                    onClick={() => {
                                      setButtonLoading(true);
                                      patchBulkUploadProducts({
                                        ...row,
                                        status: "published",
                                      })
                                        .then((_res) => {
                                          setButtonLoading(false);
                                          setSuccessMessage(
                                            translation.savedMessage
                                          );
                                          window.location.reload();
                                        })
                                        .catch((e) => {
                                          setButtonLoading(false);
                                          setErrorMessage(errorMessage(e));
                                        });
                                    }}
                                  >
                                    {
                                      translation.productsExportImport
                                        .publishProducts
                                    }
                                  </LoadingButton>
                                )}
                              </Stack>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      style={{
                        height: 100 * rowsPerPage,
                      }}
                    >
                      <TableCell colSpan={6}>
                        <Stack alignItems="center">
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
            />
          </Paper>
        ) : (
          !loading && (
            <Typography>
              {translation.productsExportImport.noCsvImports}
            </Typography>
          )
        )}
      </Box>
    </>
  );
};

export default CsvImports;
