import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { routeNames } from "routes";
import useTranslation from "components/customHooks/translations";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { backendURL } from "config";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { logout } from "services/users";
import {
  Paper,
  Stack,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { LoadingButton } from "@mui/lab";
import { postEFacturasByKey } from "services/users";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";
const EFacturaCallback = (props: any) => {
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      const key = localStorage.getItem("efacturaKey");
      const id = localStorage.getItem("efacturaId");
      console.log(key, id);
      if (key && id) {
        setLoading(true);
        postEFacturasByKey(id, key, {
          authCode: code,
        })
          .then((res) => {
            setIsSuccess(true);
            setLoading(false);
            setSuccessMessage(translation.efactura.authSuccess);
          })
          .catch((e) => {
            setLoading(false);
            setErrorMessage(errorMessage(e));
            setIsSuccess(false);
          });
      }
    } else {
      setLoading(false);
      setErrorMessage(translation.efactura.authFailed);
    }
  }, []);

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      sx={{
        height: "100vh",
      }}
    >
      <Paper sx={{ p: 4 }}>
        {loading ? (
          <CircularProgress />
        ) : isSuccess ? (
          <Typography>{translation.efactura.authSuccess}</Typography>
        ) : (
          <Typography>{translation.efactura.authFailed}</Typography>
        )}
      </Paper>
    </Stack>
  );
};

export default EFacturaCallback;
