import { IDoTask } from "interfaces";

export const DO_BUFFER = 1024;

export const getMaxPosition = (doTasks: IDoTask[]) => {
  if (doTasks.length === 0) {
    return DO_BUFFER;
  }
  return Math.max(...doTasks.map((task) => task.position)) + DO_BUFFER;
};

export const getAveragePosition = (
  doTasks: IDoTask[],
  index: number,
  direction: "top" | "bottom"
) => {
  if (doTasks.length === 0) {
    return DO_BUFFER;
  }
  if (direction === "top") {
    return (doTasks[index].position + doTasks[index - 1].position) / 2;
  } else {
    if (doTasks[index + 1] === undefined) {
      return doTasks[index].position / 2;
    }
    return (doTasks[index].position + doTasks[index + 1].position) / 2;
  }
};

export function isShallowEqual(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  return keys1.every((key1) => Object.is(obj1[key1], obj2[key1]));
}
