import { useState, useEffect } from "react";
import { useUserContext } from "components/contexts/UserContext";
import { IEfactura } from "interfaces";
import { getEFacturasByTenant } from "services/tenants";

export default function useEfactura() {
  const [efactura, setEfactura] = useState<IEfactura | null>(null);
  const [updateEfacturaTrigger, setUpdateEfacturaTrigger] = useState(false);

  const [efacturaLoading, setEfacturaLoading] = useState(true);

  const { selectedTenant, tenants } = useUserContext();

  useEffect(() => {
    setEfacturaLoading(true);
    if (tenants !== null) {
      if (tenants[selectedTenant]) {
        getEFacturasByTenant(tenants[selectedTenant].id)
          .then((res) => {
            if (res.data["hydra:member"].length > 0) {
              setEfactura(res.data["hydra:member"][0]);
              setEfacturaLoading(false);
            }
            setEfacturaLoading(false);
          })
          .catch((e) => {
            setEfacturaLoading(false);
          });
      }
    }
  }, [selectedTenant, tenants, updateEfacturaTrigger]);

  return {
    efactura,
    updateEfacturaTrigger,
    setUpdateEfacturaTrigger,
    efacturaLoading,
    setEfacturaLoading,
  };
}
