import React from "react";
import { useField } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { NearMeTwoTone } from "@mui/icons-material";

const DateTimePickerField = ({
  label,
  name,
  disablePast = false,
}: {
  label?: string;
  name: string;
  disablePast?: boolean;
}) => {
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <>
      <DateTimePicker
        onChange={(newValue) => {
          helper.setValue(newValue?.toISOString());
        }}
        value={field.value ? dayjs(field.value) : null}
        label={label}
        minDate={disablePast ? dayjs() : null}
        defaultValue={meta.initialValue ? dayjs(meta.initialValue) : null}
        slotProps={{
          textField: {
            error: !!meta.error,
            helperText: meta.error,
          },
        }}
      />
    </>
  );
};

export default DateTimePickerField;
