import {
  Collapse,
  Stack,
  Typography,
  Box,
  Divider,
  Checkbox,
  Chip,
  IconButton,
} from "@mui/material";
import { IDoTask } from "interfaces";
import { formattedDate } from "helpers";
import useTranslation from "components/customHooks/translations";
import { useEffect, useRef, useState } from "react";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
  type Edge,
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import invariant from "tiny-invariant";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { dropTargetForExternal } from "@atlaskit/pragmatic-drag-and-drop/external/adapter";
import { preserveOffsetOnSource } from "@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source";
import { isShallowEqual } from "./taskHelpers";
import { DragHandle } from "@mui/icons-material";
import { token } from "@atlaskit/tokens";
import { createPortal } from "react-dom";

type TTaskItemState =
  | {
      type: "idle";
    }
  | {
      type: "is-dragging";
    }
  | {
      type: "is-dragging-and-left-self";
    }
  | {
      type: "is-over";
      dragging: DOMRect;
      closestEdge: Edge;
    }
  | {
      type: "preview";
      container: HTMLElement;
      dragging: DOMRect;
    };

const idle: TTaskItemState = { type: "idle" };

function TaskItem({
  doTask,
  index,
  toggleDoTask,
  setDoTaskToEdit,
  setShowNewDoTask,
}: {
  doTask: IDoTask;
  index: number;
  toggleDoTask: (doTask: IDoTask) => void;
  setDoTaskToEdit: (doTask: IDoTask) => void;
  setShowNewDoTask: (show: boolean) => void;
}) {
  const translation = useTranslation();
  const [state, setState] = useState<TTaskItemState>(idle);

  const ref = useRef(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;
    invariant(el);
    invariant(dragHandle);

    return combine(
      draggable({
        element: dragHandle,
        getInitialData: ({ element }) => ({
          list: doTask.list,
          position: doTask.position,
          id: doTask.id,
          index: index,
          rect: element.getBoundingClientRect(),
        }),
        onGenerateDragPreview({ nativeSetDragImage, location }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: preserveOffsetOnSource({
              element: el,
              input: location.current.input,
            }),
            render({ container }) {
              setState({
                type: "preview",
                container,
                dragging: dragHandle.getBoundingClientRect(),
              });
            },
          });
        },
        onDragStart: () => setState({ type: "is-dragging" }),
        onDrop: () => setState(idle),
      }),
      dropTargetForExternal({
        element: el,
      }),
      dropTargetForElements({
        element: el,

        getData: ({ element, input }) => {
          return attachClosestEdge(
            {
              list: doTask.list,
              position: doTask.position,
              id: doTask.id,
              index: index,
            },
            { element, input, allowedEdges: ["top", "bottom"] }
          );
        },
        onDragEnter: ({ source, self }) => {
          const closestEdge = extractClosestEdge(self.data);
          if (!closestEdge) {
            return;
          }
          setState({
            type: "is-over",
            dragging: source.data.rect as DOMRect,
            closestEdge,
          });
        },
        onDrag({ source, self }) {
          if (source.data.id === doTask.id) {
            return;
          }
          const closestEdge = extractClosestEdge(self.data);
          if (!closestEdge) {
            return;
          }
          // optimization - Don't update react state if we don't need to.
          const proposed: TTaskItemState = {
            type: "is-over",
            dragging: source.data.rect as DOMRect,
            closestEdge,
          };
          setState((current) => {
            if (isShallowEqual(proposed, current)) {
              return current;
            }
            return proposed;
          });
        },
        onDragLeave({ source }) {
          if (source.data.id === doTask.id) {
            setState({ type: "is-dragging-and-left-self" });
            return;
          }
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      })
    );
  }, [doTask, index]);

  return (
    <Stack ref={ref}>
      {state.type === "is-over" && state.closestEdge === "top" && (
        <Divider sx={{ borderWidth: 1, borderColor: "primary.main" }} />
      )}
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        sx={state.type === "is-dragging" ? { opacity: 0.5 } : {}}
      >
        <Checkbox
          checked={doTask.done}
          onClick={(e) => {
            e.stopPropagation();
            toggleDoTask(doTask);
          }}
        ></Checkbox>
        <Box
          component="div"
          flex={1}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setDoTaskToEdit(doTask);
            setShowNewDoTask(true);
          }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            flexGrow={1}
            alignItems={"center"}
            sx={{ minHeight: 100 }}
          >
            <Stack flexGrow={1}>
              <Typography component="div">
                <Box sx={{ fontWeight: "bold", m: 1 }}>{doTask.name}</Box>
              </Typography>
              <Box
                sx={{
                  display: "inline-grid",
                }}
              >
                <Typography
                  sx={{
                    color: "text.secondary",
                    minWidth: 0,
                  }}
                  variant="subtitle1"
                  noWrap
                >
                  {doTask.description}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              sx={{
                minWidth: 200,
                maxWidth: 200,
              }}
              flexWrap={"wrap"}
            >
              {doTask.tags?.map((tag) => (
                <Chip key={tag["@id"]} label={tag.name} sx={{ m: 1 }}></Chip>
              ))}
            </Stack>
            <Stack>
              <Typography variant="caption">
                {translation.do.dueDate}
              </Typography>

              <Typography variant="body2" noWrap>
                {formattedDate(doTask.dueDate)}
              </Typography>
            </Stack>
            {doTask.doneDate && (
              <Stack>
                <Typography variant="caption">
                  {translation.do.doneDate}
                </Typography>

                <Typography variant="body2" noWrap>
                  {formattedDate(doTask.doneDate)}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
        <IconButton ref={dragHandleRef}>
          <DragHandle />
        </IconButton>
      </Stack>
      {state.type === "is-over" && state.closestEdge === "bottom" && (
        <Divider sx={{ borderWidth: 1, borderColor: "primary.main" }} />
      )}
      {state.type === "preview"
        ? createPortal(
            <Box
              sx={{
                display: "inline-grid",
              }}
            >
              <Typography component="div">
                <Box sx={{ fontWeight: "bold", m: 1 }}>{doTask.name}</Box>
              </Typography>
            </Box>,
            state.container
          )
        : null}
    </Stack>
  );
}

export default TaskItem;
