import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, InputBase, Paper, Stack, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { getDoTasksByTenant, newDoTask, updateDoTask } from "services/do";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IDoTask } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { delay, errorMessage } from "helpers";
import DoTaskForm from "./DoForm";
import { SendOutlined } from "@mui/icons-material";
import ding from "assets/sounds/ding.mp3";
import TaskList from "./TaskList";
import { getMaxPosition } from "./taskHelpers";

const Do = () => {
  const [audio] = useState(new Audio(ding));

  const { userId, selectedTenant, tenants } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const { setLoading } = useLoadingContext();
  const translation = useTranslation();
  const [newDoName, setNewDoName] = useState("");
  const [showNewDoTask, setShowNewDoTask] = useState(false);
  const [doTaskToEdit, setDoTaskToEdit] = useState<IDoTask | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [doneFilter, setDoneFilter] = useState(false);
  const [disableDone, setDisableDone] = useState(false);

  const [doTasks, setDoTasks] = useState<IDoTask[]>([]);

  const createDoTask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setDoneFilter(false);
      if (newDoName !== "") {
        let position = 0;
        if (e.shiftKey) {
          position = getMaxPosition(
            doTasks.filter((doTask) => doTask.list === "today")
          );
        } else {
          position = getMaxPosition(
            doTasks.filter((doTask) => doTask.list === "do")
          );
        }
        newDoTask({
          name: newDoName,
          owner: "/users/" + userId,
          tenant: "/tenants/" + tenants[selectedTenant].id,
          list: e.shiftKey ? "today" : "do",
          position: position,
        })
          .then((response) => {
            setDoTasks((prev) => [
              response.data as unknown as IDoTask,
              ...prev,
            ]);
            setSuccessMessage(translation.savedMessage);
            setNewDoName("");
          })
          .catch((e) => setErrorMessage(e.response.data.message));
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getDoTasksByTenant(tenants[selectedTenant].id, doneFilter)
        .then((res) => {
          const sortedDoTasks = res.data["hydra:member"].sort(
            (a: IDoTask, b: IDoTask) => b.position - a.position
          );
          console.log(sortedDoTasks);
          setDoTasks(sortedDoTasks);

          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [selectedTenant, tenants, updateTrigger, doneFilter]);

  const toggleDoTask = (doTask: IDoTask) => {
    if (!disableDone) {
      setDisableDone(true);
      if (doTask.done == false) {
        audio.currentTime = 0;
        audio.play();
      }
      setDoTasks((prev) => [
        ...prev.map((doTaskMap) => {
          if (doTaskMap == doTask) {
            return { ...doTask, done: !doTask.done };
          } else {
            return doTaskMap;
          }
        }),
      ]);
      updateDoTask({ ...doTask, done: !doTask.done })
        .then((_res) => {
          delay(1000).then(() => {
            setDoTasks((prev) => [...prev.filter((i) => i.id !== doTask.id)]);
          });
          setSuccessMessage(translation.savedMessage);
          setDisableDone(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setDisableDone(false);
        });
    }
  };
  return (
    <Box>
      <Stack spacing={4}>
        <Stack>
          {/* <Stack alignItems={"end"}>
                        <Button
                            variant="outlined"
                            startIcon={<HiveOutlined />}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            {translation.filters}
                        </Button>
                    </Stack>
                    <Collapse in={showFilters}>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <Switch
                                checked={doneFilter}
                                onChange={(e) => setDoneFilter(!doneFilter)}
                            ></Switch>
                            <Typography> done tasks</Typography>
                        </Stack>
                    </Collapse> */}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Switch
              checked={doneFilter}
              onChange={() => setDoneFilter(!doneFilter)}
            ></Switch>
            <Typography> done tasks</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Paper sx={{ p: 2, mb: 4 }}>
            <Stack>
              <Stack
                sx={{ width: "100%" }}
                direction={"row"}
                spacing={2}
                alignItems={"center"}
              >
                <SendOutlined color={"disabled"} />
                <InputBase
                  sx={{ width: "100%" }}
                  required
                  id="outlined-required"
                  value={newDoName}
                  onChange={(e) => {
                    setNewDoName(e.target.value);
                  }}
                  onKeyDown={(e) =>
                    createDoTask(e as React.KeyboardEvent<HTMLInputElement>)
                  }
                  placeholder={translation.do.newDoLabel}
                />
              </Stack>
            </Stack>
          </Paper>
          {!doneFilter ? (
            <Stack spacing={4}>
              <Box>
                <TaskList
                  title={translation.do.dos}
                  list={"do"}
                  doTasks={doTasks.filter((doTask) => doTask.list === "do")}
                  toggleDoTask={toggleDoTask}
                  setDoTaskToEdit={setDoTaskToEdit}
                  setShowNewDoTask={setShowNewDoTask}
                  setUpdateTrigger={setUpdateTrigger}
                  setAllDoTasks={setDoTasks}
                ></TaskList>
              </Box>
              <Box>
                <TaskList
                  title={translation.do.today}
                  list={"today"}
                  doTasks={doTasks.filter((doTask) => doTask.list === "today")}
                  toggleDoTask={toggleDoTask}
                  setDoTaskToEdit={setDoTaskToEdit}
                  setShowNewDoTask={setShowNewDoTask}
                  setUpdateTrigger={setUpdateTrigger}
                  setAllDoTasks={setDoTasks}
                ></TaskList>
              </Box>
              <Box>
                <TaskList
                  title={translation.do.watch}
                  list={"watch"}
                  doTasks={doTasks.filter((doTask) => doTask.list === "watch")}
                  toggleDoTask={toggleDoTask}
                  setDoTaskToEdit={setDoTaskToEdit}
                  setShowNewDoTask={setShowNewDoTask}
                  setUpdateTrigger={setUpdateTrigger}
                  setAllDoTasks={setDoTasks}
                ></TaskList>
              </Box>
              <Box>
                <TaskList
                  title={translation.do.later}
                  list={"later"}
                  doTasks={doTasks.filter((doTask) => doTask.list === "later")}
                  toggleDoTask={toggleDoTask}
                  setDoTaskToEdit={setDoTaskToEdit}
                  setShowNewDoTask={setShowNewDoTask}
                  setUpdateTrigger={setUpdateTrigger}
                  setAllDoTasks={setDoTasks}
                ></TaskList>
              </Box>
            </Stack>
          ) : (
            <Stack spacing={4}>
              <Box>
                <TaskList
                  title={translation.do.dos}
                  list={"do"}
                  doTasks={doTasks}
                  toggleDoTask={toggleDoTask}
                  setDoTaskToEdit={setDoTaskToEdit}
                  setShowNewDoTask={setShowNewDoTask}
                  setUpdateTrigger={setUpdateTrigger}
                  setAllDoTasks={setDoTasks}
                ></TaskList>
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
      <DoTaskForm
        showNewDoTask={showNewDoTask}
        setShowNewDoTask={setShowNewDoTask}
        doTaskToEdit={doTaskToEdit}
        setDoTaskToEdit={setDoTaskToEdit}
        updateTrigger={updateTrigger}
        setUpdateTrigger={setUpdateTrigger}
      ></DoTaskForm>
    </Box>
  );
};

export default Do;
