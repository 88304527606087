import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import {
  createIntegrations,
  getIntegrationsByTenant,
  updateIntegrations,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IAppIntegrations, ISettings, ITax } from "interfaces";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import FormTextField from "components/Form/FormTextField/FormTextField";

const Integrations = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [integrations, setIntegrations] = useState<IAppIntegrations>({});
  const [localUpdateTrigger, setLocalUpdateTrigger] = useState(false);
  const translation = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (tenants !== null) {
      getIntegrationsByTenant(tenants[selectedTenant].id)
        .then((res) => {
          if (res.data["hydra:member"].length === 0) {
            createIntegrations({
              tenant: "tenants/" + tenants[selectedTenant].id,
            }).then(() => {
              setUpdateTrigger(!updateTrigger);
            });
          } else {
            setIntegrations(res.data["hydra:member"][0]);

            setLoading(false);
          }
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setLoading(false);
        });
    }
  }, [selectedTenant, tenants, updateTrigger, localUpdateTrigger]);

  const submitForm = async (values: any, actions: any) => {
    try {
      await updateIntegrations({
        id: integrations.id,
        ["ga4"]: values.ga4,
        ["code"]: values.code,
      });
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      return;
    }
    actions.setSubmitting(false);
    setLocalUpdateTrigger(!localUpdateTrigger);

    setSuccessMessage(translation.savedMessage);
  };

  return (
    <>
      <PapperCollapse>
        <Formik
          initialValues={{
            ga4: integrations["ga4"] ? integrations["ga4"] : "",
            code: integrations["code"] ? integrations["code"] : "",
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            submitForm(values, actions);
          }}
        >
          {({ isSubmitting, submitForm, values, resetForm }) => (
            <Box sx={{ height: "100%" }}>
              <Stack spacing={2}>
                <Box>
                  <FormTextField
                    name="ga4"
                    label={translation.integrations.ga4Label}
                  ></FormTextField>
                </Box>
                <Box>
                  <FormTextField
                    name="code"
                    multiline
                    minRows={4}
                    label={translation.integrations.codeLabel}
                  ></FormTextField>
                </Box>

                <Box display={"flex"} justifyContent="flex-start">
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {translation.save}
                  </LoadingButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </PapperCollapse>
    </>
  );
};

export default Integrations;
