import { useEffect, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import { createEFactura } from "services/tenants";
import { IEfactura } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Button, Popover } from "@mui/material";
import { Paper } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { useTenantContext } from "components/contexts/TenantContext";
import { Stack } from "@mui/material";
import useTranslation from "components/customHooks/translations";
import { useUserContext } from "components/contexts/UserContext";

import { errorMessage, formattedDate } from "helpers";
import { ContentCopyOutlined, LinkOutlined } from "@mui/icons-material";
const EFactura = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { loading, setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const {
    efactura,
    updateEfacturaTrigger,
    setUpdateEfacturaTrigger,
    efacturaLoading,
  } = useTenantContext();
  const [efacturaCreateLoading, setEfacturaCreateLoading] = useState(false);
  const [anafAuthUrl, setAnafAuthUrl] = useState("");
  const translation = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createEfactura = () => {
    setEfacturaCreateLoading(true);
    createEFactura({
      status: "pending",
      tenant: "/tenants/" + tenants[selectedTenant].id,
    })
      .then((res) => {
        setEfacturaCreateLoading(false);
        setUpdateTrigger(!updateTrigger);
        setUpdateEfacturaTrigger(!updateEfacturaTrigger);
        setSuccessMessage(translation.efactura.efacturaActivated);
      })
      .catch((e) => {
        setErrorMessage(errorMessage(e));
        setEfacturaCreateLoading(false);
      });
  };

  useEffect(() => {
    if (efactura != null) {
      setAnafAuthUrl(
        window.location.origin +
          "/efactura/auth/" +
          efactura.id +
          "/" +
          efactura.key
      );
    }
  }, [efactura]);

  return (
    <Paper sx={{ p: 2 }}>
      {efacturaLoading == true ? (
        <>
          <Typography>{translation.efactura.waitForEfactura}</Typography>
        </>
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {translation.efacturaTitle}
          </Typography>
          {efactura == null ? (
            <Stack spacing={2}>
              <Typography>{translation.efactura.efacturaNotActive}</Typography>
              <Box>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={createEfactura}
                  loading={efacturaCreateLoading}
                >
                  {translation.efactura.activateEfactura}
                </LoadingButton>
              </Box>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Typography color="primary">
                  {translation.efactura.status}:{" "}
                </Typography>
                <Typography>{efactura.status}</Typography>
              </Stack>
              {efactura.status != "active" ? (
                <Stack spacing={2}>
                  <Typography>
                    {translation.efactura.connectToAnafMessage}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<LinkOutlined />}
                      onClick={() => window.open(anafAuthUrl, "_blank")}
                    >
                      {translation.efactura.connectToAnaf}
                    </Button>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ContentCopyOutlined />}
                        onClick={(event) => {
                          navigator.clipboard.writeText(anafAuthUrl);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        {translation.efactura.copyUrl}
                      </Button>
                      <Popover
                        open={open}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography>
                          {translation.efactura.copiedUrl}
                        </Typography>
                      </Popover>
                    </Box>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="row" spacing={2}>
                  <Typography>{translation.efactura.expiresAt}: </Typography>
                  <Typography>
                    {formattedDate(efactura.createdAt, 365)}
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}
        </>
      )}
    </Paper>
  );
};

export default EFactura;
