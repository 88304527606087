import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useTranslation from "components/customHooks/translations";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onConfirm: () => void;
  loading?: boolean;
  variant?: "confirm" | "delete";
}

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  message,
  onConfirm,
  loading,
  variant = "confirm",
}: ConfirmationDialogProps) => {
  const translation = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {translation.cancel}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onConfirm}
          loading={loading}
        >
          {variant === "confirm"
            ? translation.confirm
            : translation.deleteButton}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
