import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, Drawer, Stack, Typography } from "@mui/material";
import { formDrawerWidth } from "../constants";
import useTranslation from "components/customHooks/translations";

const AddImageUrl = ({
  title,
  multiple = false,
  showForm = false,
  handleClose,
  handleSubmitUrls,
}: {
  title?: string;
  multiple: boolean;
  showForm: boolean;
  handleClose: any;
  handleSubmitUrls: any;
}) => {
  const translation = useTranslation();
  const [imageUrls, setImageUrls] = useState([""]);
  return (
    <Drawer
      anchor="right"
      open={showForm !== false}
      onClose={() => {
        handleClose();
        setImageUrls([""]);
      }}
      sx={{
        "& .MuiDrawer-paper": { width: formDrawerWidth },
      }}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant="h5">
          {translation.products.uploadImageUrl}
        </Typography>
        <Typography variant="h6">{title}</Typography>
        {multiple ? (
          imageUrls.map((value: string, index: number) => {
            return (
              <TextField
                key={"url-" + index}
                variant="standard"
                multiline={multiple}
                value={value}
                onChange={(e) => {
                  const oldValues = [...imageUrls];
                  oldValues[index] = e.target.value;
                  if (index === oldValues.length - 1) {
                    oldValues.push("");
                  }
                  if (e.target.value === "") {
                    oldValues.splice(index, 1);
                  }
                  setImageUrls(oldValues);
                }}
                label={
                  multiple
                    ? translation.products.imageUrlsMultiple
                    : translation.products.imageUrlsSingle
                }
              ></TextField>
            );
          })
        ) : (
          <TextField
            variant="standard"
            multiline={multiple}
            value={imageUrls[0]}
            onChange={(e) => {
              const oldValues = [...imageUrls];
              oldValues[0] = e.target.value;

              setImageUrls(oldValues);
            }}
            label={
              multiple
                ? translation.products.imageUrlsMultiple
                : translation.products.imageUrlsSingle
            }
          ></TextField>
        )}
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Button
            variant={"outlined"}
            onClick={() => {
              handleClose();
              setImageUrls([""]);
            }}
          >
            {translation.closeButton}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              () => {
                const imageUrlsSubmitted = [...imageUrls];
                imageUrlsSubmitted.pop();
                handleSubmitUrls(imageUrlsSubmitted);
                setImageUrls([""]);
              };
            }}
          >
            {translation.saveButton}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default AddImageUrl;
